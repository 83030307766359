import React, { useEffect, useState } from 'react';
import Container from '../components/Container';
import logo from "../assets/images/logo.png";
import axios from 'axios';
import { Alert, Button, Popconfirm } from 'antd';
import Preloader from '../components/Preloader';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../store/user/userSlice';
import { useNavigate } from 'react-router-dom';
import AppointmentTable from '../components/AppointmentTable';

const Appointment = () => {
    const [aptData, setAptData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resp = await axios.get(`${process.env.REACT_APP_BASE_URL}/getappointment.php`);
                setAptData(resp.data);
                console.log(resp.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const logout = () => {
        dispatch(setCurrentUser(null));
    };

    if (loading) return <Preloader />;
    if (error) return <Alert message="Error in Data Fetching" />;

    return (
        <Container>
            <div className="flex justify-between items-center border-b-2 border-primary">
                <div className="flex gap-5 items-center">
                    <img src={logo} alt="logo" className='w-[90px]' />
                    <h1 className='text-3xl font-medium text-primary'>Appointments</h1>
                </div>
                <Popconfirm
                    title="Are you sure you want to log out?"
                    onConfirm={logout}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button onClick={() => navigate("/")} type="primary" className='mr-2' size="large">Leads</Button>
                    <Button type="primary" size="large">Logout</Button>
                </Popconfirm>
            </div>
            <div className='mt-5'>
                <AppointmentTable data={aptData} />
            </div>
        </Container>
    );
}

export default Appointment;
