import React, { useState, useMemo } from 'react';
import { Table, Tabs, Input, DatePicker, Button } from 'antd';
import * as XLSX from 'xlsx';

const { RangePicker } = DatePicker;

const AppointmentTable = ({ data }) => {
    // Memoize unique provinces
    const uniqueProvinces = useMemo(() => {
        return [...new Set(data.map(item => item.province))];
    }, [data]);

    // State to handle active province
    const [activeProvince, setActiveProvince] = useState(uniqueProvinces[0]);

    // Filter states for each province
    const [filters, setFilters] = useState({
        [uniqueProvinces[0]]: { city: '', salesperson: '', dateRange: [null, null] },
    });

    const handleFilterChange = (province, key, value) => {
        setFilters(prev => ({
            ...prev,
            [province]: { ...prev[province], [key]: value },
        }));
    };

    // Filtered data logic based on active province and filters
    const filteredData = useMemo(() => {
        return data.filter(item => {
            const itemDate = new Date(item.date); // Directly using the date
            const currentFilters = filters[activeProvince] || { city: '', salesperson: '', dateRange: [null, null] };

            const matchesProvince = item.province === activeProvince;
            const matchesCity = item.city.toLowerCase().includes(currentFilters.city.toLowerCase());
            const matchesSalesperson = item.salesperson.toLowerCase().includes(currentFilters.salesperson.toLowerCase());
            const matchesDateRange = currentFilters.dateRange[0] && currentFilters.dateRange[1]
                ? itemDate >= currentFilters.dateRange[0] && itemDate <= currentFilters.dateRange[1]
                : true;

            return matchesProvince && matchesCity && matchesSalesperson && matchesDateRange;
        });
    }, [activeProvince, filters, data]);

    // Columns for the table
    const columns = [
        { title: 'City', dataIndex: 'city', key: 'city' },
        { title: 'Services', dataIndex: 'services', key: 'services' },
        { title: 'Salesperson', dataIndex: 'salesperson', key: 'salesperson' },
        { title: 'Date', dataIndex: 'date', key: 'date' },
        { title: 'Time', dataIndex: 'time', key: 'time' },
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Phone', dataIndex: 'phone', key: 'phone' },
        { title: 'Address', dataIndex: 'address', key: 'address' },
        { title: 'Additional Notes', dataIndex: 'additional_notes', key: 'additional_notes' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
    ];

    const handleExport = () => {
        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Filtered Data');

        // Use the activeProvince for the file name
        const fileName = `${activeProvince.replace(/[^a-zA-Z0-9]/g, '_')}_Filtered_Data.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    const tabItems = uniqueProvinces.map(province => ({
        key: province,
        label: province,
        children: (
            <div>
                <div className="filter-container mb-4">
                    <Input
                        placeholder="City"
                        value={filters[province]?.city || ''}
                        onChange={e => handleFilterChange(province, 'city', e.target.value)}
                        style={{ width: '200px', marginRight: '10px' }}
                    />
                    <Input
                        placeholder="Salesperson"
                        value={filters[province]?.salesperson || ''}
                        onChange={e => handleFilterChange(province, 'salesperson', e.target.value)}
                        style={{ width: '200px', marginRight: '10px' }}
                    />
                    <RangePicker
                        onChange={dates => {
                            handleFilterChange(province, 'dateRange', dates ? [dates[0].toDate(), dates[1].toDate()] : [null, null]);
                        }}
                        style={{ marginRight: '10px' }}
                    />
                    <Button disabled={filteredData.length < 1} type="primary" onClick={handleExport}>
                        Export
                    </Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={{ pageSize: 5 }}
                    scroll={{ x: true }}
                    rowKey="id"
                    className="ant-responsive-table"
                />
            </div>
        ),
    }));

    return (
        <div>
            <Tabs
                activeKey={activeProvince}
                onChange={key => {
                    setActiveProvince(key);
                    // Reset filter states for the new tab if needed
                    if (!filters[key]) {
                        setFilters(prev => ({ ...prev, [key]: { city: '', salesperson: '', dateRange: [null, null] } }));
                    }
                }}
                items={tabItems}
                className="mb-4"
                centered
            />
        </div>
    );
};

export default React.memo(AppointmentTable);
